<template>
  <div class="container page" style="background-color: #1b1b1b">
    <div class="header">
      <van-nav-bar title="출금 기록" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div>
    <div class="main" style="background-color: #1b1b1b">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="Success"
      >
        <template #pulling> Scroll down to refresh </template>
        <template #loosing> Scroll down to refresh </template>
        <template #loading> loading </template>
        <van-empty v-if="list.length === 0" description="Empty data!" />
        <div v-else class="item_list" v-for="(v, key) in list" :key="key">
          <div class="topInfo">
            <span v-if="v.status === 2" style="color: #ffcc99">{{
              v.status_text
            }}</span>
            <span v-else>{{ v.status_text }}</span>
            <span style="color: #ffcc99">금액:-{{ v.money }}</span>
          </div>
          <div class="desc">
            <span>비고:{{ v.desc }}</span>
          </div>
          <div class="time">
            <span>제출 시간:{{ v.create_time }}</span>
          </div>
          <div class="time">
            <span>처리 시간:{{ v.update_time }}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: [],
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("성공");
        this.isLoading = false;
      }, 500);
    },
    getUserWithdrawList() {
      this.$http({
        method: "get",
        url: "user_get_withdraw_list",
      }).then((res) => {
        if (res.code === 200) {
          this.list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserWithdrawList();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main {
  position: relative;
  overflow: auto;
  background-color: #1b1b1b;
  height: 100%;
  padding: 0 10px;
}

.item_list {
  padding: 15px 15px;
  margin: 30px 10px;
  background: transparent;
  border-radius: 10px;
  line-height: 60px;
  border: 1px solid #ffcc99;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ffcc99;
}
.item_list .time span {
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #ffcc99;
}

.item_list .topInfo span:last-child {
  float: right;
}
.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
</style>
